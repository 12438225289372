import React, { Fragment } from 'react';
import CardSection from './CardSection.js';

function ApplySection() {
    const cardsData = [
        {
            imageSrc: '/images/university.png',
            title: 'University',
            description: 'We Are Very Familiar With College Applications And Will Tailor An Application Plan For Each Student. Our Students Have Successfully Entered World Renowned Universities Such As The University Of Toronto, UBC, University Of Edinburgh, And King\'s College London.'
        },
        {
            imageSrc: '/images/college.png',
            title: 'College',
            description: 'Canada\'s Public Colleges Prioritize The Development Of Students\' Professional Skills, Offering Highly Practical Coursework And Hands-On Experience To Ensure Students Master A Skillset During Their Time At School.'
        },
        {
            imageSrc: '/images/master.png',
            title: 'Master',
            description: 'We Assist Applicants In Comparing And Selecting From 126+ Top Global University Programs. Regardless Of Background Or Major, We Can Match You With The Most Suitable Program. Tailored Solutions Are Provided For Master\'s Students, Ensuring Each Applicant Gains Admission To A Better Institution For Further Studies.'
        },
        {
            imageSrc: '/images/CAP.png',
            title: 'Conditional Admission Program (CAP)',
            description: 'We Understand The Admission Requirements Of Various Colleges And Universities, Assisting Students From All Backgrounds In Gaining Better Access To Their Desired Institutions. We Help Students Enter University Language Centers For Study, Enabling Them To Transition To Formal Coursework Even Without Language Proficiency Scores.'
        }
    ];

    return (
        <Fragment>
            <div className='apply section'>
                <h2>apply</h2>
            </div>
            <CardSection cardsData={cardsData} />
        </Fragment>

    );
}

export default ApplySection;
