import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom';
import "../styles/nav.css";


export default function HeadNav() {
    return (
        <Navbar expand="lg" className="navbar navbar-expand-lg navbar-light">
            <Container>
                <Navbar.Brand bsPrefix='navbar-logo' href="/"><img
                    src="/images/WEA_logo.png"
                    className="d-inline-block align-top"
                    alt="KAI Global School logo"
                /></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto justify-content-center">
                        <Nav.Link href="/">Home</Nav.Link>
                        <NavDropdown align="start md" title="Academy" id="basic-nav-dropdown">
                            <NavDropdown.Item href="https://evaschool.ca/">Emerald Valley Academy</NavDropdown.Item>
                            <NavDropdown.Item href="https://kaischool.ca">
                                KAI Global School
                            </NavDropdown.Item>
                            <NavDropdown.Item href="https://osaprivateschool.com">OSA Private School</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/academy/university">OSSD Recognition</NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown title="VISA" id="basic-nav-dropdown">
                        <NavDropdown.Item href="#action/3.1">Study</NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.1">Travel</NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.1">PGWP</NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown title="Apply University" id="basic-nav-dropdown">
                        <NavDropdown.Item href="#action/3.1">University</NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.1">College</NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown title="Other Services" id="basic-nav-dropdown">
                        <NavDropdown.Item href="#action/3.1">Homestay</NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.1">Community Involment</NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.1">Custodianship</NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown title="Test Guide" id="basic-nav-dropdown">
                            <NavDropdown.Item href="#action/3.1">Contact us</NavDropdown.Item>
                        </NavDropdown>
                        <Nav.Link href="#link">About us</Nav.Link>
                        <Nav.Link href="#link">中文</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

