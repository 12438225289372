import React,{useEffect} from 'react';
import './App.css';

import { HashRouter, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Home from './pages/Home';
import PageFooter from './components/Footer';

import University from './pages/University';
import HeroWrapper from './helper/HeroWrapper';

function App() {
  useEffect(() => {
    // Create and append the first preconnect link element
    const preconnectLink1 = document.createElement('link');
    preconnectLink1.rel = 'preconnect';
    preconnectLink1.href = 'https://fonts.googleapis.com';
    document.head.appendChild(preconnectLink1);

    // Create and append the second preconnect link element
    const preconnectLink2 = document.createElement('link');
    preconnectLink2.rel = 'preconnect';
    preconnectLink2.href = 'https://fonts.gstatic.com';
    preconnectLink2.crossOrigin = 'anonymous'; // Use crossOrigin attribute
    document.head.appendChild(preconnectLink2);

    // Create and append the stylesheet link element
    const stylesheetLink = document.createElement('link');
    stylesheetLink.rel = 'stylesheet';
    stylesheetLink.href = 'https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap';
    document.head.appendChild(stylesheetLink);

    // Clean up the effect by removing the link elements on component unmount
    return () => {
      document.head.removeChild(preconnectLink1);
      document.head.removeChild(preconnectLink2);
      document.head.removeChild(stylesheetLink);
    };
  }, []);
  return (
    <div className="App">
      <HashRouter>
        <HeroWrapper />
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/academy/university" element={<University />} />
        </Routes>
      </HashRouter>
      <PageFooter />
    </div>
  );
}

export default App;
