import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../styles/CarouselComponent.css';

const images = [
    'images/offer/offers/img1.png',
    // 'images/offer/offers/img2.jpg',
    'images/offer/offers/img3.png',
    'images/offer/offers/img4.png',
    'images/offer/offers/img5.png',
    'images/offer/offers/img6.png',
    'images/offer/offers/img7.png',
    'images/offer/offers/img8.png',
    'images/offer/offers/img9.png',
    'images/offer/offers/img10.png',
    'images/offer/offers/img11.png',
    'images/offer/offers/img12.png',
    'images/offer/offers/img13.png',
    // 'images/offer/offers/img14.jpg',

];

const CarouselComponent = () => {
    const settings = {
        infinite: true,
        centerMode: true,
        centerPadding: '0',
        slidesToShow: 5,
        speed: 500,
        focusOnSelect: true,
        arrows:true,
        autoplay: true,
        autoplaySpeed:2000,
    };

    return (
        <div className="carousel-container">
            <Slider {...settings}>
                {images.map((image, index) => (
                    <div key={index} className="slider-item">
                        <img src={image} alt={`Slide ${index + 1}`} />
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default CarouselComponent;
