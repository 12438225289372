import React , {useState, useEffect } from 'react';
import HeadNav from "../components/Nav";
import Info from "../components/Info";


export default function HomeHeader(){
    const images = [
        '/images/pexels-ivan-samkov-5676744.jpg',
        '/images/pexels-yan-krukau-8617813.jpg'
    ];
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex(prevIndex => (prevIndex + 1) % images.length);
        }, 5000); // Change image every 5 seconds

        return () => clearInterval(interval); // Cleanup interval on component unmount
    }, [images.length]);
    return (
        <div id="header-container" style={{ backgroundImage: `url(${images[currentImageIndex]})` }}>
                <Info />
                <HeadNav />
                <header className="header">
                    <div className="header-content" >
                        <h1>MAKE YOUR <br /> <span>DREAMS TAKE FLIGHT</span></h1>
                        <p>
                            Wingspan Education Alliance operates three high quality high schools in Canada,
                            offering students a unique and diverse educational experience to help them advance
                            into world-renowned universities.
                        </p>
                        <button className="apply-button">APPLY NOW</button>
                    </div>
                </header>
            </div>
    )
}