import React from 'react';
import { useLocation } from 'react-router-dom';
import Info from '../components/Info';
import HeadNav from '../components/Nav';
import HomeHeader from '../components/HomeHeader';

function HeroWrapper() {
    const location = useLocation();
    const isHomePage = location.pathname === '/';

    return isHomePage ? (
            <HomeHeader />
    ) : (
        <>
            <Info />
            <HeadNav />
        </>
        
    );
}

export default HeroWrapper;