import React from "react";


export default function Info(){
    return (
        <div className="navbar-contact">
          <div className="contact-item">
            <img src="/images/icons/TEL.png" alt="KAI Global School Phone" />
            <span>+1 437-268-6158</span>
          </div>
          <div className="contact-item">
            <img src="/images/icons/EMAIL.png" alt="KAI Global School Email" />
            <span>info@wingspanedu.ca</span>
          </div>
        </div>
    )
}