import React from 'react';

import CarouselComponent from '../components/Slide';
import ApplySection from '../components/ApplySection';
import VisaSection from '../components/VisaSection';

import "../styles/home.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import OtherSection from '../components/OtherSection';
import Gallery from '../components/Gallery';


export default function Home() {
    return (
        <div id="home">
            <div className='study-in-canada section'>
                <h2>STUDY IN CANADA</h2>
                <p>Become a student in Canada and choose a local high school that suits you. Develop your unique personality in a diverse, engaging, and professional environment!</p>
            </div>
            <div id="school-container">
                <div className="school-section full-width eva-school">
                    <div className='content-logo'>
                        <img src="images/EVA_logo.png" alt="KAI Global School Emerald Valley Academy Logo" />
                    </div>
                    <div className='content-word'>
                        <h2>Emerald Valley Academy</h2>
                        <p>Located in North York, North of Toronto, is an internationally recognized high school accredited by the Ontario Ministry of Education (BSID: 886768). The school offers both online and offline high school courses, issues the Ontario Secondary School Diploma (OSSD), and boasts a high university acceptance rate.</p>
                        <a href="https://evaschool.ca/" className="button" target="_blank" rel="noopener noreferrer">
                Know More
            </a>
                    </div>
                </div>
                <div className="school-section half-width osa-school">

                    <div className='content-logo'>
                        <img src="images/osalogo copy 2.png" alt="KAI Global School OSA Private School Logo" />
                    </div>
                    <div className='content-word'>
                        <h2>OSA Private School</h2>
                        <p>OSA private school (BSID: 889262) is located in the capital city of Canada, Ottawa. It offers excellent high school curriculum teaching and boasts professional facilities for hockey, soccer, basketball, and dance, along with expert coaches, providing students with a vibrant campus experience.</p>
                        <a href="https://osaprivateschool.com" className="button" target="_blank" rel="noopener noreferrer">
                Know More
            </a>
                    </div>
                </div>
                <div className="school-section half-width kai-school">

                    <div className='content-logo'>
                        <img src="images/KAILOGO.png" alt="KAI Global School Logo" />
                    </div>
                    <div className='content-word'>
                        <h2>KAI Global School</h2>
                        <p>KAI Global School is a global high school (Ontario Ministry of Education BSID 665538) dedicated to cultivating leaders through differentiated education who are also valuable members of the community, country and world, catering to different learning styles, communication, collaboration, creation strength and critical thinking. Provides online OSSD courses and ESL English courses to students from all over the world.</p>
                        <a href="https://kaischool.ca" className="button" target="_blank" rel="noopener noreferrer">
                Know More
            </a>
                    </div>
                </div>
            </div>
            <div className='offer section'>
                <h2>OFFER</h2>
                <p>Become a student in Canada and choose a local high school that suits you. Develop your unique personality in a diverse, engaging, and professional environment!</p>
            </div>
            <div className='offer-container'>
            <CarouselComponent />
            </div>
            <ApplySection />
            <VisaSection />
            <OtherSection />
            <Gallery />
            <div className='chat-section'>
            <p>Let&apos;s chat!</p>
            <button className='help-button'>1V1 Help</button>
            </div>
        </div >
    )
}