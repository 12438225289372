import React from 'react';
import PropTypes from 'prop-types';
import '../styles/Card.css';

function Card({ imageSrc, title, subtitle,description }) {
    return (
        <div className="card">
            <img src={imageSrc} alt={title} className="card-image" />
            <h2 className="card-title">{title}</h2>
            {subtitle && <h2 className="card-subtitle">{subtitle}</h2>}
            {description && <p className="card-description">{description}</p>}
        </div>
    );
}

Card.propTypes = {
    imageSrc: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    subtitle:PropTypes.string,
    description: PropTypes.string,
};

export default Card;
