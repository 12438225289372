import React, { Fragment } from 'react';
import CardSection from './CardSection.js';

function VisaSection() {
    const cardsData = [
        {
            imageSrc: '/images/TRAVEL.png',
            title: 'Travel/Visit',
            subtitle: '旅游签证'
        },
        {
            imageSrc: '/images/STUDY.png',
            title: 'Study',
            subtitle: '学习签证'
        },
        {
            imageSrc: '/images/WORK.png',
            title: 'Work',
            subtitle: '工作签证'
        },
        {
            imageSrc: '/images/PGWP.png',
            title: 'PGWP',
            subtitle: '毕业工签'
        }
    ];

    return (
        <Fragment>
            <div className='visa section'>
                <h2>visa</h2>
            </div>
            <CardSection cardsData={cardsData} />
        </Fragment>
    );
}

export default VisaSection;
