import React from "react";
import PropTypes from 'prop-types';

export default function Title(props) {
    const styles = {
        backgroundImage: `url(/images/pexels-yan-krukau-8617813.jpg)`,
        backgroundSize: "cover",
        backgroundPosition: "center", // Set background position to top
        backgroundRepeat: "no-repeat",
        width: "auto",
        height: "14rem",
        opacity: "0.8",
        marginBottom: "4rem",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
    }
    return (
        <div className="title" style={styles}>
            <h2>{props.title}</h2>
        </div>
    )
}

Title.propTypes = {
    title: PropTypes.string.isRequired,
};