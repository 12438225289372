const UniversityData = {
    USA: [
        {
            name: "University of California",
            link: "https://admission.universityofcalifornia.edu/admission-requirements/international-applicants/applying-for-admission/freshman-requirements-country.html#canada",
            img: "UC.jpg",
        },
        {
            name: "New York University",
            link: "https://connect.nyu.edu/portal/credentials_iqtool?tab=qualifications-applicantt",
            img: "New_York_University.jpg",
        },
        {
            name: "Pennsylvania State University",
            link: "https://admissions.psu.edu/info/future/international/credentials/canada-except-quebec/",
            img: "Penn-State.jpg",
        },
        {
            name: "Boston University",
            link: "https://www.bu.edu/admissions/apply/international/school-credentials/",
            img: "BostonUniversity.jpg",
        },
        {
            name: "University of Wisconsin-Madison",
            link: "https://admissions.wisc.edu/international/",
            img: "the-university-of-wisconsin-madison.jpg",
        },
        {
            name: "University of Illinois at Urbana-Champaign",
            link: "https://www.admissions.illinois.edu/self-reported/canada-courses",
            img: "University of Illinois.jpg",
        }
    ],
    UK: [
        {
            name: "Imperial College London",
            link: "https://www.imperial.ac.uk/study/apply/undergraduate/entry-requirements/accepted-qualifications/",
            img: 'Imperial College London.png',
        },
        {
            name: "University College London",
            link: "https://www.ucl.ac.uk/prospective-students/international/canada",
            img: "university-college-london-ucl.jpg",
        },
        {
            name: "The University of Edinburgh",
            link: "https://www.ed.ac.uk/studying/international/country/americas/canada",
            img: 'u of edinburgh.jpg',
        },
        {
            name: "The University of Manchester",
            link: "https://www.manchester.ac.uk/study/international/country-specific-information/canada/entry-requirements/#country-profile",
            img: "university-of-manchester.jpg",
        },
        {
            name: "King's College London",
            link: "https://www.kcl.ac.uk/study/international/region/canadaa",
            img: "King_s College London.jpg",
        },
        {
            name: "The London School of Economics and Political Science (LSE)",
            link: "https://www.lse.ac.uk/study-at-lse/international-students/country-pages/canada",
            img: "lse.jpg",
        },
        {
            name: "University of Leeds",
            link: "https://www.leeds.ac.uk/admissions-qualifications/19525/canada",
            img: 'University of Leeds.jpg',
        },
        {
            name: "University of Glasgow",
            link: "https://www.gla.ac.uk/international/country/canada/#undergraduateentryrequirementss",
            img: "university of _glasgow.jpg",
        },
        {
            name: "University of Southampton",
            link: "https://www.southampton.ac.uk/uni-life/international/your-country/north-america/canada.page#entryreqs",
            img: "southhampton.jpg",
        },
        {
            name: "University of Birmingham",
            link: "https://www.birmingham.ac.uk/international/students/country/canada/index.aspx",
            img: "University of Birminham.jpg",
        },
        {
            name: "University of Bristol",
            link: "https://www.bristol.ac.uk/international/countries/canada.html",
            img: "university of bristol.jpg",
        }
    ],
    Canada: [
        {
            name: "University of Toronto",
            link: "https://future.utoronto.ca/apply/requirements/canadian-high-school/",
            img: "UT_logo.jpg",
        },
        {
            name: "McGill University",
            link: "https://www.mcgill.ca/undergraduate-admissions/apply/requirements/ontario",
            img: "McGill.jpg",
        },
        {
            name: "University of British Columbia",
            link: "https://you.ubc.ca/applying-ubc/requirements/canadian-high-schools/#ontarioo",
            img: "UBC.jpg",
        },
        {
            name: "University of Alberta",
            link: "https://www.ualberta.ca/admissions/undergraduate/admission/admission-requirements/index.html?",
            img: "U of Alberta.jpg",
        },
        {
            name: "University of Waterloo",
            link: "https://uwaterloo.ca/future-students/admissions/admission-requirements",
            img: "waterloo.jpg",
        },
        {
            name: "Western University",
            link: "https://welcome.uwo.ca/next-steps/requirements/ontario-high-school.html",
            img: "western canada.jpg",
        },
        {
            name: "University of Calgary",
            link: "https://www.ucalgary.ca/future-students/undergraduate/how-apply/documents",
            img: "University of Calgary.jpg",
        },
        {
            name: "Mcmaster University",
            link: "https://future.mcmaster.ca/apply/requirements/",
            img: "McMaster.jpg",
        },
        {
            name: "University of Ottawa",
            link: "https://www.uottawa.ca/study/undergraduate-studies/program-prerequisites/ontario-admission-requirements",
            img: "ottawa.jpg",
        },
    ],
    Singapore: [
        {
            name: "National University of Singapore",
            link: "https://www.nus.edu.sg/oam/apply-to-nus/international-qualifications/admissions-requirements/canadian-high-school-pre-u",
            img: "NUS.jpg",
        },
        {
            name: "Nanyang Technological University, Singapore",
            link: "https://www.ntu.edu.sg/admissions/undergraduate/admission-guide/international-qualifications/canada-qualificationss",
            img: "ntu.jpg",
        }
    ],
    HK: [
        {
            name: "The University of Hong Kong",
            link: "https://admissions.hku.hk/apply/international-qualifications#admissions-standard",
            img: "university-of-hong-kong.jpg",
        },
        {
            name: "The Chinese University of Hong Kong (CUHK)",
            link: "https://admission.cuhk.edu.hk/international/requirements.html",
            img: "CUHK.jpg",
        },
        {
            name: "The Hong Kong University of Science and Technology",
            link: "https://join.hkust.edu.hk/admissions/international-qualifications#canadian-patterned",
            img: "HKUST_L3.jpg",
        },
        {
            name: "The Hong Kong Polytechnic University",
            link: "https://www.polyu.edu.hk/study/ug/admissions/international-other-qualifications/international-general-entrance-requirements",
            img: "HKPU.jpg",
        },
        {
            name: "City University of Hone Kong",
            link: "https://www.cityu.edu.hk/international/admissions/entrance-requirements",
            img: "CityUHK.jpg",
        },
        {
            name: "hong kong baptist university",
            link: "https://admissions.hkbu.edu.hk/en/international.html",
            img: "Hong-Kong-Baptist-University.jpg",
        }
    ],
    Australian: [
        {
            name: "The University of Melbourne",
            link: "https://study.unimelb.edu.au/connect-with-us/international/canada",
            img: 'the-university-of-melbourne.jpeg',
        },
        {
            name: "The University of New South Wales (UNSW Sydney)",
            link: "https://www.unsw.edu.au/study/how-to-apply/accepted-qualifications",
            img: "unsw.jpg",
        },
        {
            name: "The University of Sydney",
            link: "https://www.sydney.edu.au/study/applying/how-to-apply/undergraduate/recognised-qualifications.html",
            img: "university-of-sydney.jpg",
        },
        {
            name: "Australian National University",
            link: "https://www.anu.edu.au/files/guidance/2023%20UG%20Entry%20Requirements%20table.pdf",
            img: "Australian National University.jpg",
        },
        {
            name: "Monash University",
            link: "https://www.monash.edu/admissions/entry-requirements/minimum",
            img: "monash.jpg",
        },
        {
            name: "The University of Queensland",
            link: "https://study.uq.edu.au/sites/default/files/2020-03/uq-international-undergraduate-guide.pdf",
            img: "The University of Queensland.png",
        },
        {
            name: "The University of Western Australia",
            link: "https://www.uwa.edu.au/study/how-to-apply/international-and-overseas-qualifications/canadian-matriculation-except-quebec",
            img: "University of Western Ausjtralia.jpg",
        },
        {
            name: "The University of Adelaide",
            link: "https://international.adelaide.edu.au/admissions/how-to-apply/admission-requirements#requirements",
            img: "the university of Adelaide.jpg",
        },
        {
            name: "university of technology, sydney",
            link: "https://www.uts.edu.au/study/international/essential-information/academic-requirements",
            img: "UTS.jpg",
        }
    ]
};
export default UniversityData;