import React, { Fragment } from 'react';
import CardSection from './CardSection.js';

function OtherSection() {
    const cardsData = [
        {
            imageSrc: '/images/HOMESTAY.png',
            title: 'Homestay',
            description: 'Tailoring Suitable Homestay Arrangements For Students, Ensuring Their Study Life In Canada Is As Comfortable As Being At Home.'
        },
        {
            imageSrc: '/images/CI.png',
            title: 'Community Involvement',
            description: 'Community Activities Are Mandatory For High School Students In Ontario, Serving As Vital Lessons That Enhance Teenagers\' Understanding Of Society And Integration Into It.'
        },
        {
            imageSrc: '/images/Custodianship.png',
            title: 'Custodianship',
            description: 'Offering Dependable, Professional Guardianship Services For Underage Students. We Maintain Close Communication With Students\' Parents, Providing Attentive Daily Support To Ensure Smooth And Joyful Academic Experiences.'
        }
    ];

    return (
        <Fragment>
            <div className='other section'>
                <h2>other</h2>
            </div>
            <CardSection cardsData={cardsData} />
        </Fragment>

    );
}

export default OtherSection;
