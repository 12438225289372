import React from 'react';
import Card from './Card.js';
import PropTypes from 'prop-types';
import '../styles/CardSection.css';

function CardSection({cardsData}) {
    return (
        <div className="card-section">
            <div className="cards-container">
                {cardsData.map((card, index) => (
                    <Card
                        key={index}
                        imageSrc={card.imageSrc}
                        title={card.title}
                        subtitle={card.subtitle}
                        description={card.description}
                    />
                ))}
            </div>
        </div>
    );
    
}
CardSection.propTypes = {
    cardsData: PropTypes.arrayOf(PropTypes.shape({
        imageSrc: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        subtitle:PropTypes.string,
        description: PropTypes.string,
    })).isRequired,
};

export default CardSection;
