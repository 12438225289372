import React from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import '../styles/Gallery.css';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const imageData = [
    '/images/photos/18.jpg',
    '/images/photos/3c502c0937590a5b17a2553b8eac2a0.png',
    '/images/photos/f5640a29b4cef42c084ffff2577b623.png',
    '/images/photos/797eedaa726c9f1f8fa01b7af23d46b.png',
    '/images/photos/dbc3ce1ccb0a569ae00df7fcd5c54d1.png',
    '/images/photos/DSC05268-HDR.JPG',
    '/images/photos/DJI_0006.jpg',
    '/images/photos/IMG_9644.JPG',
    '/images/photos/DSC05077.JPG',
];

const breakpoints = { lg: 1200, md: 768, sm: 480 };
const cols = { lg: 3, md: 2, sm: 2 };
const width = {lg:1,md:2,sm:2};
const height = {lg:2,md:3,sm:2};

function Gallery() {
    const generateLayout = (columns,width,height) => {
        return imageData.map((src, index) => ({
            i: index.toString(),
            x: (index % columns),
            y: Math.floor(index / columns),
            w: width, // Each item spans 1 column
            h: height, // Each item spans 1 row
        }));
    };

    const layouts = {
        lg: generateLayout(cols.lg,width.lg,height.lg),
        md: generateLayout(cols.md,width.md,height.md),
        sm: generateLayout(cols.sm,width.sm,height.sm),
    };

    return (
        <div className="gallery-wrapper">
            <div className="gallery-container">
                <ResponsiveReactGridLayout
                    className="layout"
                    layouts={layouts}
                    breakpoints={breakpoints}
                    cols={cols}
                    rowHeight={100}
                >
                    {imageData.map((src, index) => (
                        <div key={index} className="grid-item">
                            <img src={src} alt={`KAI Global School Gallery ${index}`} className="gallery-image" />
                        </div>
                    ))}
                </ResponsiveReactGridLayout>
            </div>
        </div>
    );
}

export default Gallery;
