import React from 'react';
import Footer from 'rc-footer';
import 'rc-footer/assets/index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import '../styles/Footer.css'; // Import the custom CSS file

export default function PageFooter() {
    return (
        <div>
            <Footer
                className="custom-footer" // Apply the custom CSS class
                columns={[
                    {
                        title: 'QUICK LINK',
                        items: [
                            {
                                title: 'EMERALD VALLEY ACADEMY',
                                url: 'https://evaschool.ca/',
                                openExternal: true,
                            },
                            {
                                title: 'OSA Private School',
                                url: 'https://osaprivateschool.com',
                                openExternal: true,
                            },
                            {
                                title: 'KAI School',
                                url: 'https://kaischool.ca',
                                openExternal: true,
                            },
                        ],
                    },
                    {
                        title: 'CONTACT US',
                        items: [
                            {
                                icon: <FontAwesomeIcon icon={faEnvelope} />,
                                title: 'info@wingspanedu.ca',
                                url: 'https://pro.ant.design/',
                                openExternal: true,
                            },
                            {
                                icon: <FontAwesomeIcon icon={faMapMarkerAlt} />,
                                title: '4750 Yonge St, Toronto,Canada, M2N 5M6',
                                url: 'https://mobile.ant.design/',
                                openExternal: true,
                            },
                        ],
                    },
                ]}
                bottom="©2023 WEA. All rights reserved"
                theme="dark"
                columnLayout="space-around"
            />
        </div>
    );
}
