import React from "react";
import Title from "../components/Title";
import UniversityData from "../data/UniversityData";
import "../styles/PageStyle/University.css";

export default function University() {
    return (
        <div className="university main-section">
            <Title title="Leading Universities Recognizing OSSD" />
            <div className="university-container">
                {
                    Object.keys(UniversityData).map(
                        (country) => (
                            <div className={`country ${country}`} key={country}>
                                <h1>{country}</h1>
                                <div className="grid-university grid-section">
                                    {
                                        Array.isArray(UniversityData[country]) && UniversityData[country].map(
                                            (university) => (
                                                <a
                                                    key={university.name}
                                                    href={university.link}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <img
                                                        src={`/images/university_logo/${university.img}`}
                                                        alt={university.name}
                                                    />
                                                </a>
                                            )
                                        )
                                    }
                                </div>
                            </div>
                        )
                    )
                }
            </div>
        </div>
    );
}
